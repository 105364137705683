<template>
  <div>
    <b-modal
      id="modal-no-backdrop"
      hide-footer
      hide-header
      v-model="model_checkout"
    >
      <div>
        <div v-if="!data_check_packages">
          <b-overlay
            :show="show"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-row>
              <b-col md="12" xl="12">
                <!-- <b-card> -->
                <div style="margin-top: -20px">
                  <b-img
                    :src="appLogoImage"
                    style="max-width: 70px"
                    alt="logo"
                  />
                  <b-img
                    :src="appName"
                    id="logo2"
                    style="max-width: 120px"
                    alt="logo"
                  />
                </div>
                <h3><b>ซื้อแพ็กเกจใหม่</b></h3>
                <hr />

                <!-- <packages ref="packages"></packages> -->
                <div>
                  <!-- ------------------------------------------------------------------------------------>
                  <div v-if="type_status === 'monthly'">
                    <table>
                      <tr>
                        <th id="idheard">
                          <b>แพ็กเกจที่ซื้อ </b>
                        </th>
                        <th>
                          {{ data.package_data.name }}

                          {{ type_heard }}
                          {{
                            new Intl.NumberFormat("en-IN").format(
                              data.package_data.price_m
                            )
                          }}
                          {{ type }}
                        </th>
                      </tr>

                      <tr>
                        <th id="idheard">
                          <b>ระยะเวลาใช้งาน </b>
                        </th>
                        <th>
                          {{
                            $moment(
                              data.package_data.collect_month_start
                            ).format("DD/MM/YYYY")
                          }}-
                          {{
                            $moment(data.package_data.collect_month_end).format(
                              "DD/MM/YYYY"
                            )
                          }}
                        </th>
                      </tr>

                      <tr>
                        <th id="idheard">
                          <b>ค่าบริการทั้งหมด </b>
                        </th>
                        <th>
                          {{
                            new Intl.NumberFormat("en-IN").format(
                              data.package_data.collect_month_total
                            ) + " บาท"
                          }}
                        </th>
                      </tr>
                      <tr>
                        <th id="idheard"><b>วิธีชำระเงิน </b></th>
                        <th>Credit Card</th>
                      </tr>
                      <!-- <tr>
                        <th id="idheard">
                          <b>วันต่ออายุอัตโนมัติ </b>
                        </th>
                        <th>ทุกวันที่ 1 ของทุกเดือน</th>
                      </tr> -->
                    </table>
                    <hr />
                  </div>
                  <!-- ------------------------------------------------------------------------------------>
                  <div v-else>
                    <table>
                      <tr>
                        <th id="idheard">
                          <b>แพ็กเกจที่ซื้อ </b>
                        </th>
                        <th>
                          {{ data.package_data.name }}

                          {{ type_heard }}
                          {{
                            new Intl.NumberFormat("en-IN").format(
                              data.package_data.price_y
                            )
                          }}
                          {{ type }}
                        </th>
                      </tr>

                      <tr>
                        <th id="idheard">
                          <b>ระยะเวลาใช้งาน </b>
                        </th>
                        <th>
                          {{
                            $moment(
                              data.package_data.collect_year_start
                            ).format("DD/MM/YYYY")
                          }}-
                          {{
                            $moment(data.package_data.collect_year_end).format(
                              "DD/MM/YYYY"
                            )
                          }}
                        </th>
                      </tr>

                      <tr>
                        <th id="idheard">
                          <b>ค่าบริการทั้งหมด </b>
                        </th>
                        <th>
                          {{
                            new Intl.NumberFormat("en-IN").format(
                              data.package_data.collect_year_total
                            ) + " บาท"
                          }}
                        </th>
                      </tr>
                      <tr>
                        <th id="idheard"><b>วิธีชำระเงิน </b></th>
                        <th>จ่ายผ่านบัตรเครดิต{{ card_credit }}</th>
                      </tr>
                      <!-- <tr>
                        <th id="idheard">
                          <b>วันต่ออายุอัตโนมัติ </b>
                        </th>
                        <th>ทุกวันที่ 1 ของทุกเดือน</th>
                      </tr> -->
                    </table>
                    <hr />
                  </div>
                </div>

                <div class="blue box ex1">
                  <div class="coral item">
                    <b-form-group>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="status"
                        name="checkbox-1"
                      >
                        ข้าพเจ้ายอมรับ
                        <a
                          href="https://socio.co.th/term-of-use/"
                          target="_blank"
                          style="color: #2bc85e"
                        >
                          ข้อกำหนดการใช้บริการ
                        </a>
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                <hr />

                <div class="blue box ex1">
                  <div class="coral item">
                    <b-button
                      v-if="set_credit && payment_method === 'card_auto'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      @click="model_checkout = false"
                    >
                      ยกเลิก
                    </b-button>
                    &nbsp&nbsp&nbsp&nbsp&nbsp
                    <b-button
                      v-if="set_credit && payment_method === 'card_auto'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="omise_packages_schedules()"
                    >
                      ซื้อ
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
        <div v-else>
          <section v-if="data_check_packages">
            <b-row class="invoice-add">
              <b-col cols="12" xl="12" md="12">
                <b-card>
                  <!-- {{ data.package_data }} -->
                  <!-- {{ package }} -->
                  <div>
                    <b-img
                      :src="appLogoImage"
                      style="max-width: 70px"
                      alt="logo"
                    />
                    <b-img
                      :src="appName"
                      id="logo2"
                      style="max-width: 120px"
                      alt="logo"
                    />
                  </div>
                  <b-card-body class="invoice-padding pt-0">
                    <h3>
                      คุณมีแพ็กเกจ
                      {{ data_check_packages.package }} ที่ต้องเปลี่ยน
                    </h3>
                    <b-row class="invoice-spacing">
                      <b-col cols="6" xl="6" class="mb-lg-1">
                        <h6 class="mb-2">แพ็กเกจประเภท</h6>

                        <div class="mt-1">
                          <p>เริ่มใช้งาน</p>
                          <p>สิ้นสุดการใช้งาน</p>
                        </div>
                      </b-col>
                      <b-col cols="6" xl="6" class="mb-lg-1">
                        <h6 class="mb-2">
                          <p>
                            {{ data_check_packages.package }}
                          </p>
                        </h6>

                        <div class="mt-1">
                          <p>{{ data_check_packages.datetime_update }}</p>
                          <p>{{ data_check_packages.expiry_date }}</p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </section>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BAlert,
  BForm,
  BOverlay,
  BModal,
  VBModal,
  BImg,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormRadioGroup,
  BFormText,
  BFormDatalist,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BPopover,
  BLink,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import api from "@/api";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
// import packages from "./packages.vue";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
    // packages,
    BImg,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BAlert,
    Cleave,
    BOverlay,
    BModal,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormRadioGroup,
    BFormText,
    BFormDatalist,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BPopover,
    BLink,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      insert: false,
      model_checkout: false,
      status: true,
      show_model: false,
      timer: null,
      show: false,
      qrshow: false,
      resQR: [],
      data: [],
      package_data: [],
      data_check_packages: [],
      banks: [],
      months: [],
      months_data: [],
      set_month: 10,
      set_bank: null,
      authorize_uri: null,
      message_status: null,
      count_card_credit: 100,
      card_credit: null,
      id_card_credit: null,
      form_null: {
        cardNumber: 4242424242424242,
        expiry: "0330",
        cvv: 123,
        cardName: "JOHN DOE",
      },
      form: {
        cardNumber: null,
        expiry: null,
        cvv: null,
        cardName: null,
      },
      set_credit: null,
      set_installment: null,
      price_total: null,

      payment_method: "card_auto",
      cardNumber: "",
      Remember_me: true,
      options: {
        creditCard: {
          creditCard: true,
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        expiry: {
          delimiter: " / ",
          blocks: [2, 2],
          uppercase: true,
        },
      },
      type: null,
      type_heard: null,
      type_status: null,
      package_id: null,
    };
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.loaddata();
    this.check_packages();
  },
  methods: {
    async loaddata() {
      // this.show = true;
      api
        .get("user_card_by_omise", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.banks = response.data.banks;
          this.count_card_credit = response.data.count_card_credit;
          this.card_credit = "****" + response.data.result.last_digits;
          this.id_card_credit = response.data.result.id;
          this.set_credit = "****" + response.data.result.last_digits;
          this.set_installment = "****" + response.data.result.last_digits;
          this.show = false;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    check_packages() {
      this.show = true;
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("check_packages", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.check_packages :>> ", response.data);
          this.data_check_packages = response.data;
          this.show = false;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    package_price(id, type) {
      this.type_status = type;
      this.package_id = id;
      if (type === "monthly1") {
        this.type = " บาท/เดือน";
        this.type_heard = "รายเดือน  ";
      } else {
        this.type = " บาท/ปี";
        this.type_heard = "รายปี  ";
      }
      const params = {
        id: id,
        type: type,
        store_id: localStorage.getItem("store_id"),
      };
      console.log("params :>> ", params);
      api
        .post("package_price", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log("response.package_price :>> ", response.data);
          this.data = response.data.stores;
          this.package_data = response.data.stores.package_data;
          this.model_checkout = true;
        })
        .catch((error) => {});
    },
    omise_packages_schedules() {
      this.show = true;
      const params = {
        package_id: this.package_id,
        status: this.type_status,
        store_id: localStorage.getItem("store_id"),
        packages: this.package_data,
        type: this.type,
      };

      // console.log("params :>> ", params);
      if (this.status === true) {
        api
          .post("omise_packages_schedules", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.show = false;
            this.model_checkout = false;
            console.log(
              "response.omise_packages_schedules :>> ",
              response.data
            );
            window.location.reload();
            this.$swal({
              title: "เปลี่ยนแพ็กเกจสำเร็จ!",
              text: "ดำเนินการเปลี่ยนแพ็กเกจสำเร็จ!",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.show = false;
            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.show = false;
        this.$swal({
          title: "ข้อกำหนดการใช้บริการ!",
          text: " โปรดยอมมรับ ข้อกำหนดการใช้บริการ",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#idheard {
  text-align: right;
  padding-right: 1em;
}
#cvv {
  width: auto;
}

.ex1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
